import React from "react"
import { Helmet } from "react-helmet"
import RDStationConection from "../utils/RDStationConnection"

import Header from "../components/Header/Header"
import SectionOne from "../components/Home/SectionOne/SectionOne"
import SectionTwo from "../components/Home/SectionTwo/SectionTwo"
import SectionThree from "../components/Home/SectionThree/SectionThree"
import SectionFour from "../components/Home/SectionFour/SectionFour"
// import SectionFive from "../components/Home/SectionFive/SectionFive"
import Footer from "../components/Footer/Footer"
import SEO from "../components/SEO/SEO"
import NeoronWidget from "../utils/NeoronWidget"

export default function Home() {
  React.useEffect(() => {
    RDStationConection.init()
    NeoronWidget.init()
  }, [])
  return (
    <div style={{ width: "100%" }}>
      <SEO title="FABWORK | Transformação digital para sua realidade" />
      <Helmet>
        <meta charSet="utf-8" />
        <title>FABWORK | Transformação digital para sua realidade</title>
        <meta
          name="google-site-verification"
          content="yBczbVP15X4iTCW8iBnrb7MYl15RABSf-kScBo5_JXI"
        />
        <meta
          name="description"
          content="Nossa missão é viabilizar a transformação digital através do desenvolvimento tecnológico e de capacitações customizadas para a sua realidade."
        />

        <link rel="canonical" href="https://fab.work" />
      </Helmet>
      <Header />
      {/* <div></div> */}
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      <SectionFour />
      {/* <SectionFive /> */}
      <Footer />
    </div>
  )
}
