import React from "react"
import AOS from "aos"

import "aos/dist/aos.css"
import "./SectionOne.scss"

import intelAiBuilder from "../../../images/intel-ai-builders.svg"
import intelAiBuilder30degACW from "../../../images/intel-ai-builders--30deg.svg"
import arrow from "../../../images/shapes/arrow.svg"

function SectionOne() {
  React.useEffect(() => {
    AOS.init({
      once: true,
      duration: 2000,
      easing: "ease-in-out",
      offset: 50,
    })
  })
  return (
    <div id="section-one">
      <div id="content">
        <div>
          <div>
            <img
              className="rotate-infinite"
              src={intelAiBuilder30degACW}
              alt="Selo da Intel Ai Builders"
            />
            <h1 data-aos="fade-right">
              Pensamos dados, agimos tech, evoluímos de forma exponencial.
            </h1>
            <p data-aos="fade-right" data-aos-delay="250">
              E te ajudamos a fazer o mesmo.
            </p>
          </div>
          <div className="image-container-lg">
            <img
              className="rotate-infinite"
              src={intelAiBuilder}
              alt="Selo da Intel Ai Builders"
            />
          </div>
        </div>
        <a
          href="/contact/#form"
          data-aos="fade-right"
          data-aos-delay="1000"
          data-aos-easing="ease-in"
        >
          <button className="btn">
            Dê o primeiro passo
            <svg
              width="31"
              height="16"
              viewBox="0 0 31 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M30.7071 8.7071C31.0976 8.31658 31.0976 7.68341 30.7071 7.29289L24.3431 0.92893C23.9526 0.538406 23.3195 0.538406 22.9289 0.92893C22.5384 1.31945 22.5384 1.95262 22.9289 2.34314L28.5858 8L22.9289 13.6569C22.5384 14.0474 22.5384 14.6805 22.9289 15.0711C23.3195 15.4616 23.9526 15.4616 24.3431 15.0711L30.7071 8.7071ZM8.74228e-08 9L30 9L30 7L-8.74228e-08 7L8.74228e-08 9Z"
                fill="white"
              />
            </svg>
          </button>
        </a>
      </div>
      <div
        className="large-button"
        data-aos="fade-right"
        data-aos-delay="1000"
        data-aos-easing="ease-in"
      >
        <a href="/contact/#form">
          <button className="btn">
            Converse conosco e descubra possibilidades
            <svg
              width="56"
              height="16"
              viewBox="0 0 56 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M55.7071 8.70711C56.0976 8.31658 56.0976 7.68342 55.7071 7.29289L49.3431 0.928932C48.9526 0.538408 48.3195 0.538408 47.9289 0.928932C47.5384 1.31946 47.5384 1.95262 47.9289 2.34315L53.5858 8L47.9289 13.6569C47.5384 14.0474 47.5384 14.6805 47.9289 15.0711C48.3195 15.4616 48.9526 15.4616 49.3431 15.0711L55.7071 8.70711ZM0 9H55V7H0L0 9Z"
                fill="#F5F5F5"
              />
            </svg>
          </button>
        </a>
      </div>
      <img
        id="arrow"
        className="bounce-infinite"
        src={arrow}
        alt="Mais conteúdo abaixo"
      />
    </div>
  )
}

export default SectionOne
