import React, { useEffect } from "react"
import AOS from "aos"

import "aos/dist/aos.css"
import "./SectionTwo.scss"

import fabtech from "../../../images/fabs/fabtech.svg"
import fabacademy from "../../../images/fabs/fabacademy.svg"
import fabcorporate from "../../../images/fabs/fabcorporate.svg"

function SectionTwo() {
  useEffect(() => {
    AOS.init({
      once: true,
      duration: 1000,
      easing: "ease-in-out",
    })
  })
  return (
    <div id="section-two">
      <div className="header">
        <h1 data-aos="fade-up">Nossas Soluções</h1>
      </div>
      <div id="container">
        <section id="fabtech">
          <a href="/fabtech/#">
            <img
              data-aos="fade-right"
              data-aos-delay="1000"
              src={fabtech}
              alt="Duas mãos: Uma indo em direção a um dispositivo móvel, outra indo em direção a um na tela de um computador. Indicando desenvolvimento de software e de aplicativos da FABTECH."
            />
          </a>
          <div>
            <a href="/fabtech/#">
              <img
                data-aos="fade-right"
                src={fabtech}
                alt="Duas mãos: Uma indo em direção a um dispositivo móvel, outra indo em direção a um na tela de um computador. Indicando desenvolvimento de software e de aplicativos da FABTECH."
              />
            </a>
            <h2 data-aos="fade-left">FAB tech</h2>
            <h3 data-aos="fade-left">Soluções tecnológicas aplicadas</h3>
            <p data-aos="fade-left-p">
              Desenvolvemos tecnologias customizáveis que aprimoram a
              performance digital e analítica de empresas.
            </p>
            <a href="/fabtech/#" data-aos="fade-left">
              <button className="btn">saiba mais</button>
            </a>
          </div>
        </section>
        <section id="fabcorporate">
          <a href="/fabcorporate/#">
            <img
              data-aos="fade-left"
              data-aos-delay="1000"
              src={fabcorporate}
              alt="Um indivíduo sobe uma escada indo em direção a um gráfico. Além disso, acima do individuo tem uma pessoa com cabelos compridos."
            />
          </a>
          <div>
            <a href="/fabcorporate/#">
              <img
                data-aos="fade-left"
                // data-aos-delay="1000"
                src={fabcorporate}
                alt="Um indivíduo sobe uma escada indo em direção a um gráfico. Além disso, acima do individuo tem uma pessoa com cabelos compridos."
              />
            </a>

            <h2 data-aos="fade-right">FAB corporate</h2>
            <h3 data-aos="fade-right">Programas corporativos customizados</h3>
            <p data-aos="fade-right">
              Transformamos a performance organizacional de corporações através
              de programas customizados de capacitação e mentoria aplicada.
            </p>
            <a href="/fabcorporate/#" data-aos="fade-right">
              <button className="btn">saiba mais</button>
            </a>
          </div>
        </section>
        <section id="fabacademy">
          <a href="/fabacademy/#">
            <img
              data-aos="fade-right"
              data-aos-delay="1000"
              src={fabacademy}
              alt="Uma cabeça dividida em quadros ao lado de um relógio."
            />
          </a>
          <div>
            <a href="/fabacademy/#">
              <img
                data-aos="fade-right"
                src={fabacademy}
                alt="Uma cabeça dividida em quadros ao lado de um relógio."
              />
            </a>
            <h2 data-aos="fade-left">FAB academy</h2>
            <h3 data-aos="fade-left">Capacitações rápidas e práticas</h3>
            <p data-aos="fade-left">
              Desenvolvemos as principais habilidades da era da transformação
              digital através das nossas academias rápidas e práticas.
            </p>
            <a href="/fabacademy/#" data-aos="fade-left">
              <button className="btn">saiba mais</button>
            </a>
          </div>
        </section>
      </div>
    </div>
  )
}

export default SectionTwo
