import React, { useState } from "react"
import podcasts from "../../../data/episodes.json"

import validateEmail from "../../../utils/validateEmail"
import "../SectionFour/SectionFour.scss"

import playButton from "../../../images/icons/play-button.svg"
import illustration from "../../../images/illustrations/newsletter.svg"

const SectionFour = () => {
  const lastThreePodcasts = [podcasts[1], podcasts[2], podcasts[3]]

  const [emailIsValid, setEmailIsValid] = useState(false)
  const [email, setEmail] = useState("")

  function handleSubmitButton(event) {
    if (!emailIsValid) {
      event.preventDefault()
      event.stopPropagation()

      return false
    }
  }
  function handleSubmitForm(event) {
    event.preventDefault()
    setTimeout(() => {
      setEmail("")
      setEmailIsValid(false)
    }, 2000)

    alert("Cadastrado")
    // "https://drive.google.com/drive/u/1/folders/1yBfygiTbyD9V_zAg3Kt3YZCTjlecmhrv"
    window.open(
      "https://drive.google.com/file/d/1VKwL9Jf3sUjC43SdG2GX2FeBqLmdOKu7/view"
    )
  }

  function handleEmailChange(event) {
    const { value } = event.target
    setEmail(value)
    setEmailIsValid(validateEmail(value))
  }

  return (
    <section id="sectionFour" name="newsletter">
      <div className="center">
        <div className="text-row">
          <h1 data-aos="fade-right">Juntos Somos exponenciais</h1>
          <p data-aos="fade-left-p" data-aos-delay="500">
            Somos uma comunidade inovadora que conecta profissionais para pensar
            dados, novas tecnologias e futuros exponenciais. Entregamos
            conteúdos ricos sobre culturas data-driven em forma de newsletters,
            lives, podcasts, e-books e curadoria de notícias semanais.
          </p>

          <div className="conheca" data-aos="fade-in-up">
            <a
              href="http://fab-work.rds.land/juntos-somos-exponenciais-2021"
              target="_blank"
              rel="noreferrer"
            >
              <button className="btn">Faça parte da comunidade</button>
            </a>
          </div>
        </div>
        <div className="videos">
          {lastThreePodcasts.map((podcast, index) => (
            <div className="video-live" key={index}>
              <a href={`/episode?id=${podcast.id}`}>
                <img
                  src={podcast.image}
                  className="JSE-video-img"
                  alt={podcast.description}
                  data-aos="fade-up-lives"
                  data-aos-delay="800"
                />
                <img
                  className="play-button"
                  src={playButton}
                  alt="Botão de play"
                />
              </a>
            </div>
          ))}
        </div>
        <div className="conheca2" data-aos="fade-in-up" data-aos-delay="800">
          <a
            href="https://t.me/juntossomosexponenciais"
            target="_blank"
            rel="noreferrer"
          >
            <button className="btn">Faça parte da comunidade</button>
          </a>
        </div>
        <div id="newsletter" className="ebooks" data-aos="fade-in-up">
          <div className="mask" data-aos="fade-in-up" data-aos-delay="1000">
            <img
              className="illustration"
              src={illustration}
              alt="Ilustração mostrando uma pessoa olhando para um tablet ao lado de documentos."
            />
            <div className="newsletter-container">
              <div className="title-one">
                <h1>Amplie seu repertório em dados e tecnologia!</h1>
              </div>
              <div className="text-one">
                <p>Deixe seu email e receba a Newsletter FABWORK</p>
              </div>
              <form
                id="newsletterOfFabworkSite"
                className="newsletter"
                onSubmit={handleSubmitForm}
              >
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={email}
                  onChange={handleEmailChange}
                  placeholder="Seu email"
                />

                <button
                  className={`btn ${
                    !emailIsValid ? "disabledButton" : "abledButton"
                  }`}
                  onClick={handleSubmitButton}
                  disabled={!emailIsValid}
                  type="submit"
                >
                  enviar
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default SectionFour
