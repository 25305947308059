import React from "react"

import "../SectionThree/SectionThree.scss"
import "../../../styles/_global.scss"

import Companies from "../../Companies/Companies"

// import handsLarge from "../../../images/hands-large.svg"
// import handsSmallDown from "../../../images/handsSmallDown.svg"

const SectionThree = () => {
  return (
    <div id="fabwork-section-four">
      <Companies />
    </div>
  )
}
export default SectionThree
